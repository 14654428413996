import React from 'react'
import '../css/sideBar.css'
import {
  NavLink
} from 'react-router-dom';

function SideBar() {
   const navToggle = () => {
  const body = document.querySelector('body')
  const links = document.querySelectorAll('.link_item')
  // console.log(links)
  links.forEach(element => {
    element.classList.toggle('reveal')
  });
  body.classList.toggle('stop-scrolling');
  }
  return (
  <aside className='sidebar'>
  
   <div className="sidelinks">
    <span className=''>
      <NavLink to="/work" onClick={navToggle} className={({isActive}) =>(isActive)?'link_item':'link_item' }>Work</NavLink> 
    </span>
      
      <span className=''>
        <NavLink to="/resume" onClick={navToggle}  className={({isActive}) =>(isActive)?'link_item':'link_item'}>Resume</NavLink>
      </span>
      <span>
        <NavLink to="/about" onClick={navToggle}  className={({isActive}) =>(isActive)?'link_item':'link_item'}>About</NavLink>
      </span>
      <span>
        <NavLink to="/contact" onClick={navToggle} className={({isActive}) =>(isActive)?'link_item':'link_item'}>Contact</NavLink>
      </span>
      
      
      
    {/* <a href="/work">
     <p>Work</p>
    </a>
    <a href="/">
     <p>Resume</p>
    </a>
    <a href="/about">
     <p>About</p>
    </a>
    <a href="/contact">
     <p>Contact</p>
    </a> */}
   </div>

   <div className="sidebar_icons">
      {/* social media  */}
      <ul className="social-icons">
        <li >
          <a className='flash_link' href="https://dribbble.com/Acemanny">
            <i className="fab fa-dribbble"></i>
          </a>
        </li>
        <li >
          <a className='flash_link' href="https://www.twitter.com/Acemanny">
            <i className="fab fa-twitter"></i>
          </a>
        </li>
        <li >
          <a className='flash_link' href="https://behance.net/olatunbawodiya">
            <i className="fab fa-behance"></i>
          </a>
        </li>
        <li >
          <a className='flash_link' href="https://www.linkedin.com/in/olatunbosun-awodiya-3b5b77207">
            <i className="fab fa-linkedin"></i>
          </a>
        </li>
        {/* <li>
          <a href="https://www.twitter.com">
            <i class="fab fa-sketch"></i>
          </a>
        </li> */}
      </ul>
   </div>
   

  </aside>
  )
}

export default SideBar