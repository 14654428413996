import React, {useEffect} from 'react'
import NavB from '../components/NavB'
import '../css/work_new.css'
import {
 NavLink
} from 'react-router-dom';
import Contact from '../components/Contact'
import {split} from '../animations/text'

function Wholecare() {
   useEffect(()=>{
    split()
  })
  return (
    <div className='wholecare'>
     <NavB />
     <div className="wholecare_body">
     <div className="work_title">
      <h1 data-animation="header">
       Wholecare - a wellness app
       case study
      </h1>

      <div className="work_tags">
       <span className="work_tag_item">product design</span>
       <span className="work_tag_item">wellness</span>
       <span className="work_tag_item">mobile app</span>
      </div>

      <img src="../../assets/images/wholecare/olatunbosun_wholecare_mobile_presentation.png" alt="" />
     </div>

     <div className="work_overview">
      <div className="overview_title">
       <h2 data-animation="paragraph">overview</h2>
      </div>
      <div className="overview_contents">
       <p data-animation="paragraph">
        The objective of the Wholecare app is to 
        provide users with a comprehensive, 
        personalized approach to wellness, making it easy 
        for them to prioritize their physical and mental 
        well-being in their busy lives. Overall, the app 
        will help users feel more in control of their 
        wellness and empower them to 
        make sustainable changes in their lives.
       </p>

       <div className="overview_lists">
        <div className="role">
         <h3 data-animation="paragraph">Role</h3>
         <p data-animation="paragraph">Product designer</p>
         <p data-animation="paragraph">UX researcher</p>
        </div>
        <div className="timeline">
         <h3 data-animation="paragraph">Timeline</h3>
         <p data-animation="paragraph">2 weeks</p>
        </div>
        <div className="tools">
         <h3 data-animation="paragraph">Tools</h3> 
         <p data-animation="paragraph">Figma</p>
         <p data-animation="paragraph">Mockup - Sketch UI/UX</p>
         <p data-animation="paragraph">Notion</p>
        </div>
       </div>
      
      </div>
      
     </div>
      <div className="overview_imgs">
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_overview_1.png" alt="" />
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_overview_2.png" alt="" />
      </div>

      <div className="challenge">
       <div className="challenge_title">
        <h3 data-animation="paragraph">Challenge</h3>
       </div>
       <div className="challenge_content">
        <p data-animation="paragraph">
         Many people struggle to maintain a healthy lifestyle and 
         manage their time effectively due to a lack of resources 
         and organization. They often feel overwhelmed by the amount 
         of information available and struggle to find a routine 
         that works for them
        </p>
       </div>
      </div>

      <div className="target">
       <div className="target_title">
        <h3 data-animation="paragraph">Target Audience</h3>
       </div>
       <div className="target_content">
        <p data-animation="paragraph">
         The target audience for the app are individuals who 
         are looking to prioritize their physical and mental 
         well-being but feel overwhelmed by the amount of 
         information available. They may be struggling to 
         find a routine that works for them and are looking 
         for a personalized, holistic approach to wellness.
        </p>
       </div>
      </div>
      <div className="design_process">
       <div className="design_process_title">
        <h3 data-animation="paragraph">
         Design process
        </h3>
       </div>

       <img src="../../assets/images/wholecare/olatunbosun_awodiya_design_process_wholecare.png" alt="" />
      </div>
      <div className="problems">
       <div className="problem_title">
        <h3 data-animation="paragraph">Problems</h3>
       </div>
       <div className="problem_content">
        <ul>
         <li data-animation="paragraph">
          Difficulty finding time to prioritize physical and mental 
          well-being due to busy schedules and long working hours.
         </li>
         <li data-animation="paragraph">
          Difficulty staying motivated. Maintaining a 
          healthy lifestyle can be challenging, 
          and it can be easy to lose motivation. 
         </li>
         <li data-animation="paragraph">
          Difficulty managing time effectively, 
          which can make it difficult to prioritize 
          physical and mental well-being. 
         </li>
         <li data-animation="paragraph">
         Difficulty creating a personalized wellness routine that 
         works for them.
         </li>
        </ul>
       </div>
      </div>

     {/* User persona section */}
      <div className="user_persona">
       <div className="user_persona_title">
        <h3 data-animation="paragraph">User Personas</h3>
       </div>
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_user_persona.png" alt="" />
      </div>

      {/* Solution section */}
       <div className="problems">
       <div className="problem_title">
        <h3 data-animation="paragraph">Solutions</h3>
       </div>
       <div className="problem_content">
        <ul>
         <li data-animation="paragraph">
         To provide users with a personalized, holistic approach to wellness 
         that can be easily integrated into their daily routine.
         </li>
         <li data-animation="paragraph">
         To provide users with a sense of community, allowing 
         them to connect with others and share their progress, challenges, 
         and successes, which can help to keep them motivated.
         </li>
         <li data-animation="paragraph">
         To provide users with a time management feature that allows them to input their daily activities 
         and set reminders for important tasks, as well as break suggestions 
         that can help them stay refreshed and focused throughout the day
         </li>
         <li  data-animation="paragraph">
         To provide users with personalized meal plans, 
         fitness tracking, and mindfulness exercises that are 
         tailored to their individual needs and preferences.
         </li>
        </ul>
       </div>
      </div>

      {/* User flow */}
       <div className="design_process">
       <div className="design_process_title">
        <h3 data-animation="paragraph">
         Userflow
        </h3>
       </div>

       <img src="../../assets/images/wholecare/olatunbosun_awodiya_userflow.png" alt="" />
      </div>

      {/* Sketches section */}
      <div className="user_persona">
       <div className="user_persona_title">
        <h3 data-animation="paragraph">Sketches</h3>
       </div>
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_sketches_1.png" alt="" />
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_sketches_2.png" alt="" />
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_sketches_3.png" alt="" />
      </div>

      {/* Style guide */}
      <div className="user_persona">
       <div className="user_persona_title">
        <h3 data-animation="paragraph">Style guide</h3>
       </div>
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_style_guide_1.png" alt="" />
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_style_guide_2.png" alt="" />
       
      </div>

      {/* UI Designs */}
       <div className="user_persona">
       <div className="user_persona_title">
        <h3 data-animation="paragraph">UI Designs</h3>
       </div>
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_design_1.png" alt="" />
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_design_2.png" alt="" />
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_design_3.png" alt="" />
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_design_4.png" alt="" />
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_design_5.png" alt="" />
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_design_6.png" alt="" />
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_design_7.png" alt="" />
       <img src="../../assets/images/wholecare/olatunbosun_awodiya_design_8.png" alt="" />
      </div>


      <div className="work_controls_btns">
       <NavLink to='/work/proximar' className={({isActive}) =>(isActive)?'control':'control'} data-animation="paragraph">previous</NavLink>
       <NavLink to='/work/swiftsell' className={({isActive}) =>(isActive)?'control':'control'} data-animation="paragraph">next</NavLink>
      </div>
      <Contact />
      </div>
    </div>
  )
}

export default Wholecare