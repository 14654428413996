import React from 'react';
import '../css/Nav.css';
import {
  NavLink
} from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

function NavB() {

  const navToggle = () => {
    const navClass = document.querySelector('#nav-icon2')
    const sideBar  = document.querySelector('.sidebar');
    const body = document.querySelector('body')
    const links = document.querySelectorAll('.link_item')
    // const bounce  = document.querySelector('.bounce_link')
    const flash  = document.querySelectorAll('a.flash_link')
    // const flash2 = document.querySelectorAll('.bounce_link.flash_link')
   
    console.log(links)
    links.forEach(element => {
    element.classList.toggle('reveal')
    });
    // bounce.classList.toggle('reveal_hor')
    flash.forEach(f=>{
      f.classList.toggle('reveal_hor')
    })
    
  // bounce.classList.toggle('bounce')
  
    navClass.classList.toggle('open')
     sideBar.classList.toggle('show-sidebar');
    body.classList.toggle('stop-scrolling');
  }

  const navToggle2 = () => {
    const menu = document.querySelector('.mobile_bottom')
    menu.classList.toggle('hide')
  }

  const homeToggle = () => {
    const body = document.querySelector('body')
    body.classList.remove('stop-scrolling');
  }

  const toggleActive = (e) => {
    const lists = document.querySelectorAll('.toggle');
    lists.forEach(list => {
        lists.forEach(item=>{
          if(list !== item){
            console.log(list)
            console.log(item)
            item.classList.remove('active')
          }
          
        })
        list.classList.add('active')

    });
  
  }
  return (
  <nav>
      <div className="mobile">
        <div className="mobile_top">
          <div className="mobile_logo">
            <NavLink to='/' onClick={homeToggle}  className={({isActive}) =>(isActive)?'mobile_logo':'mobile_logo'}>AO</NavLink>
          </div>
          
          {/* <div id="nav-icon2" onClick={navToggle}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div> */}
          <div className="menu" onClick={navToggle2}>
            Menu
          </div>
        </div>
        <div className="mobile_bottom hide">
          <ul>
            
            <NavLink to="/work"  className={({isActive}) =>(isActive)?'active':'toggle'}>Work</NavLink> 
            <a href="https://drive.google.com/file/d/183a69OmocfSc1uGTSH2W_cUXnaejyoTG/view" className='toggle'>Resume</a>
            <NavLink to="/about"  className={({isActive}) =>(isActive)?'active':'toggle'}>About</NavLink>
            <NavHashLink smooth to='/#contact_home' className={({isActive}) =>(isActive)?'toggle':'toggle'}>contact</NavHashLink>
          </ul>

        </div>
       
      </div>
      <div className="logo">
        <NavLink to="/"  className={({isActive}) =>(isActive)?'logo':'logo'}>AO</NavLink>
      </div>
      
     <ul>
  
      <NavLink to="/work"  className={({isActive}) =>(isActive)?'active':'toggle'}>Work</NavLink> 
      <a href="https://drive.google.com/file/d/183a69OmocfSc1uGTSH2W_cUXnaejyoTG/view"  className='toggle'>Resume</a>
      <NavLink to="/about" className={({isActive}) =>(isActive)?'active':'toggle'}>About</NavLink>
<NavHashLink smooth to='/#contact_home'  className={({isActive}) =>(isActive)?'toggle':'toggle'}>contact</NavHashLink>
     </ul>

     <div></div>
    </nav>
  )
}

export default NavB