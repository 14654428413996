import React,{useRef, useEffect} from 'react'
import NavB from '../components/NavB'
// import ArrowOutward from '@material-ui/icons/Arrow'
// import ArrowOutward from '@mui/icons-material/ArrowOutward';
import ModelViewer from '../components/ModelViewer'
import '../css/Home.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {gsap, Power3} from 'gsap' ;
import ScrollTrigger from "gsap/ScrollTrigger";
import { Timeline } from 'gsap/gsap-core'
import { split } from '../animations/text'
import {
  NavLink
} from 'react-router-dom';


gsap.registerPlugin(ScrollTrigger, Timeline);

function Home() {
  // let smallBorderBottom = useRef(null)

  // let prod = useRef(null)
  // let twod = useRef(null)
  // let bosun = useRef(null)
  // let awo = useRef(null)

  // let header = useRef(null)

  // let rotate = useRef(null)

  // let navAbout = useRef(null)
  // let navAbout_2 = useRef(null)
  // useEffect(()=>{
  //   AOS.init();
  //   split()
  
  //   // small border animation
  //   gsap.set(smallBorderBottom,{
  //     opacity: 0,
  //     xPercent: -100,
  //   })

  //   gsap.to(smallBorderBottom, {
  //     opacity: 1,
  //     delay: 5,
  //     ease: Power3.easeIn,
  //     duration: 10,
  //     xPercent: 0
  //   })

  //   // stagger nav links
  //   const nav = [prod, twod, bosun, awo]
  //   gsap.set(nav,{
  //     opacity: 0,
  //     yPercent: 100,
  //   })
   
  //   gsap.to(nav, {
  //     opacity: 1,
  //     delay: 4,
  //     ease: Power3.easeIn,
  //     stagger:0.5,
  //     duration:3> 100 ? 0.5 : 0.6,
  //     yPercent: 0
  //   })

  //   // stagger mobile nav.
  //   const mob_nav   = []

  //   gsap.set(navAbout,{
  //     opacity: 0,
  //     yPercent: 100,
  //   })

  //   gsap.to(navAbout,{
  //     opacity: 1,
  //     delay: 6,
  //     yPercent: 0
  //   })

  //    gsap.set(navAbout_2,{
  //     opacity: 0,
  //     yPercent: 100,
  //   })

  //   gsap.to(navAbout_2,{
  //     opacity: 1,
  //     delay: 10,
  //     yPercent: 0
  //   })

  //   gsap.set(rotate,{
  //     opacity: 0,
  //   })

  //   gsap.to(rotate, {
  //     opacity: 1,
  //     delay: 5,
  //     ease: Power3.easeIn,
  //     ease: "easeOut",
  //     duration: 10
  //   })
  // })

  
     const mouseOut = () => {
      const text = document.querySelectorAll('.fe_text')
      text.forEach(t=>{
        t.classList.add('hide')
      })
      document.querySelector('.wholecare_work').classList.remove('wholecare_hover')
     }

     const proximar = () =>{
      const details = document.querySelector('.fe_proximar')
      details.classList.toggle('hide')
     }

     const wholecare = () =>{
      const details = document.querySelector('.fe_wholecare')
      details.classList.toggle('hide')
      document.querySelector('.wholecare_work').classList.add('wholecare_hover')
     }

     const youfinance = () => {
      const details = document.querySelector('.fe_youfinance')
      details.classList.toggle('hide')
     }

     const swiftsell = () => {
      const details = document.querySelector('.fe_swiftsell')
      details.classList.toggle('hide')
     }


    const selectedOut = () => {
    const text = document.querySelectorAll('.selected_2')
    text.forEach(t=>{
      t.classList.add('hide')
    })
    }

    const case_ = () => {
      const details = document.querySelector('.case_img')
      details.classList.toggle('hide')
    }

    const archive = () => {
      const details = document.querySelector('.archive_img')
      details.classList.toggle('hide')
    }

    const illustration = () =>{
      const details = document.querySelector('.illustration_img')
      details.classList.toggle('hide')
    }
 
  const _iOSDevice = !!navigator.platform.match(/iPhone/)
  let screen = window.innerWidth;
  
  return (
   <div className='home'>
    <NavB />
    <header>
      {!_iOSDevice?(
        <div className="video_container">
          <video  muted playsInline id='myVideo' autoPlay loop  >
            <source  src='https://res.cloudinary.com/adelabuadeleke/video/upload/v1685373391/Head_Rotation_d4ty8n.mp4' type='video/mp4' />
          </video>
        </div>
      ):(
        <div className="video_container">
           <video  muted playsInline id='myVideo' autoPlay loop>
            <source src='https://res.cloudinary.com/adelabuadeleke/video/upload/v1685373391/Head_Rotation_d4ty8n.mp4' type='video/mp4' />
          </video>
        </div>
      )

      }
      
      {/* <ModelViewer scale="60" modelPath={"../../assets/Manny.glb"} /> */}
      <div className="intro__det">

       <div className="name__div">
        <div className="div_one"> 
         

        </div>
         
        
        <div>
        {!_iOSDevice?(
          <video  autoPlay muted loop className="video_min">
            <source src='https://res.cloudinary.com/adelabuadeleke/video/upload/v1685373391/Head_Rotation_d4ty8n.mp4' type='video/mp4' />
          </video>
        ):(
          <video  autoPlay muted loop playsInline className="video_min">
            <source src='https://res.cloudinary.com/adelabuadeleke/video/upload/v1685373391/Head_Rotation_d4ty8n.mp4' type='video/mp4' />
          </video>
        )}
        </div>

    
      {screen >= 768?(
        <div className="div_two">
          <div>
            <p data-animation="header"> 
              Hi I'm Olatunbosun Awodiya,
              I'm a Product Designer.
            </p>
            <p></p>
            <NavLink to='/about' className={({isActive}) =>(isActive)?'about_toggle':'about_toggle'}><p>About me<img src="../../assets/arrow_outward_black.png" alt=""/>
 </p></NavLink>
          </div>
          
          <p data-animation="header">
            Creating value for brands and businesses
            through digital products and visual experiences
          </p>
        </div>
          ):(
             <div className="div_two">
          <div>
            <p data-animation="header"> 
              Hi I'm Olatunbosun Awodiya, 
              I'm a Product Designer.
            </p>
            
          </div>
          
          <div>
            <p data-animation="header">
              Creating value for brands and businesses
              through digital products and visual experiences
            </p>
            <p></p>
            <NavLink to='/about' className={({isActive}) =>(isActive)?'about_toggle':'about_toggle'}>
              <p>About me  <img src="../../assets/arrow_outward_black.png" alt=""/></p>
              </NavLink>
          </div>
         
        </div>

          )}
       
        </div>
       <div className="third__div">
        
        <div className="third_conts">
        </div> 
        
        <div className="img_anim intro_rotate" >
          <img src="../../assets/rotate_grey.png" alt="rotating animation | Olatunbosun Awodiya" className='rotate'/>
          <div className="rotate_div">
            <img src="../../assets/arrow_down.png" alt="arrow down illustration | Olatunbosun Awodiya" className='arrow_down'/>
          </div>
          
        </div>
       </div>
       
      </div>
     </header>
     <section className="design">
    

      <div className="ux">
        	<p>product designer</p>
      </div>
      <div className="thd">
        	<p>3d illustrator</p>
      </div>
      
      <div className="twd">
        	<p>2d illustrator</p>
        
       
      
      </div>
     </section>
    <section className='featured'>
      <h2>selected works</h2>
    
      <div className="selected_first">
        <NavLink  className="parent sel sel_f proximar_work" to='/work/proximar' aria-label='proximar' onMouseOver={proximar} onMouseOut={mouseOut}>
         <div className="fe_text hide fe_proximar">
              <div class="fe_text_work">
                <div className="fe_conts">
                  <div>
                    proximar
                    <img src="../../assets/arrow_outward_black.png" alt=""  className=' youfinance_arrow'/>
                  </div>
                
                  <div className="fe_tags you_work_tag">
                    Product Design, Real Estate, Mobile App
                  </div>
                </div>
              </div>
            </div> 
        </NavLink>

         <NavLink className="parent sel sel_s wholecare_work" to='/work/wholecare' aria-label='wholecare' onMouseOver={wholecare} onMouseOut={mouseOut}>
         <div className="fe_text hide fe_wholecare">
              <div class="fe_text_work">
                <div className="fe_conts">
                  <div>
                    wholecare
                    <img src="../../assets/arrow_outward_black.png" alt=""  className=' youfinance_arrow'/>
                  </div>
                
                  <div className="fe_tags you_work_tag">
                    Product Design, Wellness
                  </div>
                </div>
              </div>
            </div> 
        </NavLink>
  
        
       
      </div>

      <div className="selected_second ">
          <a className="parent sel sel_s you_finance" aria-label='youfinance' href='https://www.behance.net/gallery/160804439/Youfinance-3D-Brand-Characters-and-Illu' onMouseOver={youfinance} onMouseOut={mouseOut}>
           
            <div className="fe_text hide fe_youfinance">
              <div class="fe_text_work">
                <div className="fe_conts">
                  <div>
                    youfinance
                    <img src="../../assets/arrow_outward_black.png" alt=""  className=' youfinance_arrow'/>
                  </div>
                
                  <div className="fe_tags you_work_tag">
                    3d Design, UI design, branding
                  </div>
                </div>
              </div>
            </div> 
        </a>

      <NavLink className="parent sel sel_f headset_ar" aria-label='swiftsell' to='/work/swiftsell' onMouseOver={swiftsell} onMouseOut={mouseOut}>
        <div className="fe_text hide fe_swiftsell">
              <div class="fe_text_work">
                <div className="fe_conts">
                  <div>
                    swiftsell
                    <img src="../../assets/arrow_outward_black.png" alt=""  className=' youfinance_arrow'/>
                  </div>
                
                  <div className="fe_tags you_work_tag">
                    Product Design, E-comm, Webapp
                  </div>
                </div>
              </div>
            </div> 
        </NavLink>
      
      
      </div>
      <div className="first">
       
       <NavLink className='case case_2' aria-label='view all' to='/work' onMouseOver={case_} onMouseOut={selectedOut}>
        <div className="fe_text_o">
          <div className="fe_cont">
            view all
            <img src="../../assets/arrow_outward_black.png" alt=""  className='hide case_img selected_2'/>
          </div>
         
         
        </div>
       </NavLink>
      
       <a href="https://dribbble.com/Acemanny" aria-label='archive' className='archive' onMouseOver={archive} onMouseOut={selectedOut}>
        <div className="fe_text_o">
          <div className="fe_cont">
            archive
            <img src="../../assets/arrow_outward_black.png" alt=""  className='hide archive_img selected_2'/>
          </div>
        </div>
        </a>
       
      </div>

      <div className="second">
       
        <NavLink className='illustrations' to='/illustrations' aria-label='illustrations' onMouseOver={illustration} onMouseOut={selectedOut}>
        <div className="fe_text_o">
          <div className="fe_cont">
            illustrations
            <img src="../../assets/arrow_outward_black.png" alt=""  className='hide illustration_img selected_2'/>
          </div>
        </div>
       </NavLink>
       
      </div>
     </section>
     <div className='contact contact_snap' id='contact_home'>
     <div className='links'>
      <div className="links_first">
       <p><a className='contact_link' href="mailto:olatunbosunawodiya@gmail.com">E-MAIL</a></p>
       <p><a className='contact_link' href="https://behance.net/olatunbawodiya">BEHANCE</a></p>
       <p><a className='contact_link' href="https://www.linkedin.com/in/olatunbosun-awodiya-3b5b77207">LINKEDIN</a></p>
       <p><a className='contact_link' href="https://dribbble.com/Acemanny">DRIBBBLE</a></p>
      </div>
      <div className="sec">
        <img src="../../assets/computer_illustration_olatunbosun_awodiya.webp" alt="Computer illustration Olatunbosun Awodiya" />
         <div className="img_anim">
          <img src="../../assets/rotate.png" alt="rotating animation | Olatunbosun Awodiya" className='rotate'/>
          <div className="rotate_div">
            <img src="../../assets/arrow_up.png" alt="arrow down illustration | Olatunbosun Awodiya" className='arrow_down'/>
          </div>
         </div>
        </div>
      
     </div>
    </div>

   </div>
    
  )
}

export default Home