import React,{useEffect} from 'react'
import NavB from '../components/NavB'
import '../css/work_new.css'
import {
 NavLink
} from 'react-router-dom';
import Contact from '../components/Contact'
import {split} from '../animations/text'

function Swiftsell() {
  useEffect(()=>{
    split()
  })
  return (
    <div className='swiftsell'> 
     <NavB />
     <div className="swiftsell_body">
      <div className="work_title">
      <h1 data-animation="header">
       Swiftsell - sales dashboard
       for digital products
      </h1>

      <div className="work_tags">
       <span className="work_tag_item">product design</span>
       <span className="work_tag_item">saas</span>
       <span className="work_tag_item">mobile app</span>
      </div>

      <img src="../../assets/images/swiftsell/olatunbosun_awodiya_swiftsell_intro.png" alt="" />
     </div>

     <div className="work_overview">
      <div className="overview_title">
       <h2 data-animation="paragraph">overview</h2>
      </div>
      <div className="overview_contents">
       <p data-animation="paragraph">
        Swiftsell is a Saas Company that provides retail management 
        platform  for businesses. Swiftsell needs to provide their 
        clients with an intuitive and data driven sales dashboard. 
        This interface would help their clients monitor sales of 
        their products , check key metrics , monitor growth and 
        sales of customers
       </p>

       <div className="overview_lists">
        <div className="role">
         <h3 data-animation="paragraph">Role</h3>
         {/* <p>Product designer</p> */}
         <p data-animation="paragraph">UI/UX Designer</p>
        </div>
        <div className="timeline">
         <h3 data-animation="paragraph">Timeline</h3>
         <p data-animation="paragraph">2 weeks</p>
        </div>
        <div className="tools">
         <h3 data-animation="paragraph">Tools</h3> 
         <p data-animation="paragraph">Figma</p>
         <p data-animation="paragraph">Mockup - Sketch UI/UX</p>
         {/* <p>Notion</p> */}
        </div>
       </div>

       
      </div>
      {/* <div className="overview_imgs"> */}
       <img src="../../assets/images/swiftsell/olatunbosun_awodiya_swiftsell_overview.png" alt="" />
      {/* </div> */}
     </div>
     
      {/* Challenge */}
       <div className="problems">
        <div className="problem_title">
         <h3 data-animation="paragraph">Challenge</h3>
        </div>
        <div className="problem_content">
        <p data-animation="paragraph">
         Design and  a sales dashboard for Swiftsell that 
         enables their clients to effectively monitor and analyze their sales data, 
         identify trends, and take actions to improve sales performance
        </p>
        </div>
        </div>

      {/* Product goals */}
       <div className="problems">
        <div className="problem_title">
         <h3 data-animation="paragraph">Goals</h3>
        </div>
        <div className="problem_content">


         <ul>
          <li data-animation="paragraph">
           Provide concise overview of sales data, performance and metrics.
          </li>
          <li data-animation="paragraph">
          Enable clients to manage and monitor the sale of products

          </li>
          <li data-animation="paragraph">
          Enable clients to monitor customer growth and to make customer relation easier.
          </li>
         </ul>
        </div>
       </div>
        {/* Wireframes */}
       <div className="user_persona">
       <div className="user_persona_title">
        <h3 data-animation="paragraph">Wireframes</h3>
       </div>
       <img src="../../assets/images/swiftsell/olatunbosun_awodiya_swiftsell_wireframe_1.png" alt="" />
       <img src="../../assets/images/swiftsell/olatunbosun_awodiya_swiftsell_wireframe_2.png" alt="" />
       <img src="../../assets/images/swiftsell/olatunbosun_awodiya_swiftsell_wireframe_3.png" alt="" />
       <img src="../../assets/images/swiftsell/olatunbosun_awodiya_swiftsell_wireframe_4.png" alt="" />
       <img src="../../assets/images/swiftsell/olatunbosun_awodiya_swiftsell_wireframe_5.png" alt="" />
      </div>

       {/* UI Designs */}
       <div className="user_persona">
       <div className="user_persona_title">
        <h3 data-animation="paragraph">UI Designs</h3>
       </div>
       <img src="../../assets/images/swiftsell/olatunbosun_awodiya_swiftsell_design_1.png" alt="" />
       <img src="../../assets/images/swiftsell/olatunbosun_awodiya_swiftsell_design_2.png" alt="" />
       <img src="../../assets/images/swiftsell/olatunbosun_awodiya_swiftsell_design_3.png" alt="" />
       <img src="../../assets/images/swiftsell/olatunbosun_awodiya_swiftsell_design_4.png" alt="" />
       <img src="../../assets/images/swiftsell/olatunbosun_awodiya_swiftsell_design_5.png" alt="" />
      </div>

        <div className="work_controls_btns">
          <NavLink to='/work/wholecare' className={({isActive}) =>(isActive)?'control':'control'} data-animation="paragraph">previous</NavLink>
          <NavLink to='/work/proximar' className={({isActive}) =>(isActive)?'control':'control'} data-animation="paragraph">next</NavLink>
        </div>
        <Contact />
     </div>
     
    </div>
  )
}

export default Swiftsell