import React from 'react'
import NavB from '../components/NavB'
import '../css/illustrations.css'
import Contact from '../components/Contact'


// illustrations imports
// import Image1 from 'https://res.cloudinary.com/adelabuadeleke/image/upload/v1678641794/IMG-20230312-WA0015_1_fiyx3u.jpg'

function Illusrations() {
  return (
    <div className='illustration'>
     <NavB />
     <div className="illustration_body">
      <div className="illustration_title">
       <h1>illustrations</h1>
       <p>A compilation of 2D and 3D Illustrations from 2021 - 2023</p>
      </div>
      <div className="illustrations_imgs">
        <div className="illustrations_row">
        <img src="../../assets/images/illustrations/olatunbosun_awodiya_illustration_1.png" alt="" />
        <img src="../../assets/images/illustrations/olatunbosun_awodiya_illustration_2.png" alt="" />
       </div>

       <div className="illustrations_row">
        <img src="../../assets/images/illustrations/olatunbosun_awodiya_illustration_3.png" alt="" />
        <img src="../../assets/images/illustrations/olatunbosun_awodiya_illustration_4.png" alt="" />
       </div>

       <div className="illustrations_row">
        <img src="../../assets/images/illustrations/olatunbosun_awodiya_illustration_5.png" alt="" />
        <img src="../../assets/images/illustrations/olatunbosun_awodiya_illustration_6.png" alt="" />
       </div>

       <div className="illustrations_row">
        <img src="../../assets/images/illustrations/olatunbosun_awodiya_illustration_7.png" alt="" />
        <img src="../../assets/images/illustrations/olatunbosun_awodiya_illustration_8.png" alt="" />
       </div>

       <div className="illustrations_row">
        <img src="../../assets/images/illustrations/olatunbosun_awodiya_illustration_9.png" alt="" />
        <img src="../../assets/images/illustrations/olatunbosun_awodiya_illustration_10.png" alt="" />
       </div>

       <div className="illustrations_row">
        <img src="../../assets/images/illustrations/olatunbosun_awodiya_illustration_11.png" alt="" />
        <img src="../../assets/images/illustrations/olatunbosun_awodiya_illustration_12.png" alt="" />
       </div>

      
      </div>
      <Contact /> 
     </div>
    </div>
  )
}

export default Illusrations