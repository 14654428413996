import React, {useEffect} from 'react'
import NavB from '../components/NavB'
import '../css/work_new.css'
import Contact from '../components/Contact'
import {
 NavLink
} from 'react-router-dom';
import {split} from '../animations/text'


function Proximar() {
  useEffect(()=>{
    split()
  })
  return (
    <div className='proximar'>
     <NavB />
     <div className="proximar_body">
     
     <div className="work_title">
      <h1 data-animation="header">
       Proximar - a real estate 
       solution
      </h1>

      <div className="work_tags">
       <span className="work_tag_item">product design</span>
       <span className="work_tag_item">real estate</span>
       <span className="work_tag_item">mobile app</span>
      </div>
      {/* <video src="" controls></video> */}
      {/* <img src="../../assets/images/wholecare/olatunbosun_wholecare_mobile_presentation.png" alt="" /> */}
      <div style={{padding:'56.25% 0 0 0',position: 'relative'}} className='vimeo'>
        <iframe src="https://player.vimeo.com/video/826975968?h=7f4dcd65b8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}} title="Proxima Main">
        </iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
     </div>

     <div className="work_overview">
      <div className="overview_title">
       <h2 data-animation="paragraph">overview</h2>
      </div>
      <div className="overview_contents">
       <p data-animation="paragraph">
       In recent years, The real estate market has 
       seen a shift towards online platforms for 
       finding and purchasing properties. Proxima 
       is a real estate solution aimed at providing 
       users with an easy and stress-free way to find 
       suitable spaces including offices, apartments, 
       and homes. The purpose is to eliminate the stress 
       and cost expenses related to getting a suitable 
       and convenient space

       </p>

       <div className="overview_lists">
        <div className="role">
         <h3 data-animation="paragraph">Role</h3>
         <p data-animation="paragraph">Product designer</p>
         <p data-animation="paragraph">3D modeller</p>
         <p data-animation="paragraph">Motion designer</p>
         <p data-animation="paragraph">UX researcher</p>
        </div>
        <div className="timeline">
         <h3 data-animation="paragraph">Timeline</h3>
         <p data-animation="paragraph">5 weeks</p>
        </div>
        <div className="tools">
         <h3 data-animation="paragraph">Tools</h3> 
         <p data-animation="paragraph">Figma</p>
         <p data-animation="paragraph">Mockup - Sketch UI/UX</p>
         <p data-animation="paragraph">Blender</p>
         <p data-animation="paragraph">Adobe Aero</p>
         <p data-animation="paragraph">After Effects</p>
         <p data-animation="paragraph">Notion</p>
        </div>
       </div>
      
      </div>
      
     </div>
      <div className="overview_imgs">
       <img src="../../assets/images/proximar/olatunbosun_awodiya_overview_1.png" alt="" />
       <img src="../../assets/images/proximar/olatunbosun_awodiya_overview_2.png" alt="" />
      </div>
      {/* Challenge */}
       <div className="problems">
        <div className="problem_title">
         <h2 data-animation="paragraph">Challenge</h2>
        </div>
        <div className="problem_content">
        <p data-animation="paragraph">
         There is a lot of challenge that goes into searching 
         for a new space be it a home, an office or an apartment.
         Some of these challenges tend to inflict stress and unexpected 
         expenses. They include but not limited to the following:
        </p>
         <ul>
          <li data-animation="paragraph">
          Lack of transparency in building conditions: Most times 
          listed homes are not listed with the current conditions 
          and maintenance issues about the building which could 
          put the buyer at a risk.
          </li>
          <li data-animation="paragraph">
          Lack of transparency in pricing details: Agents and 
          homeowners are usually not upfront with additional costs 
          aside the rental or building price. Additional costs such 
          as agent fees, inspection fees, insurance, maintenance costs  
          and other billings.
          </li>
          <li data-animation="paragraph">
          Poor visualization of building properties as sometimes 
          pictures used by agents and home owners can be deceiving.
          </li>
        
         </ul>
        </div>
        </div>

      {/* Product goals */}
           <div className="problems">
        <div className="problem_title">
         <h2 data-animation="paragraph">Product goals</h2>
        </div>
        <div className="problem_content">
        <p data-animation="paragraph">
         I narrowed down the product goals to the following: 
        </p>
         <ul>
          <li data-animation="paragraph">
           Simplify process of searching for a new space or property: 
           One of the main goals is  to make it easy for users to 
           find their desired space. This would  involve designing 
           an intuitive and user-friendly interface that would 
           enable users to narrow down their search for a new space
          </li>
          <li data-animation="paragraph">
          To provide users with up to date 
          information about the property and its conditions. 
          </li>
          <li data-animation="paragraph">
           Create a transparent and trustworthy product: To deal with 
           the problems and concerns about  lack of transparency in the 
           real estate market concerning costs and property conditions, 
           the  product would aim at  creating  a transparent and trustworthy 
           platform that provides users with all the information they need 
           to make informed decisions. This would involve providing detailed 
           information about each property, as well as reviews and ratings.
          </li>
          
          <li data-animation="paragraph">
           Provide users with a visual experience of different spaces: 
           To provide users with a way to inspect and view houses using 
           augmented reality technology.
          </li>
         </ul>
        </div>
        </div>

      {/* Research */}
      <div className="problems">
        <div className="problem_title">
         <h2 data-animation="paragraph">Research</h2>
        </div>
        <div className="problem_content">
         <h3 data-animation="paragraph">Methods</h3>
        <p data-animation="paragraph">
         To create a user-centric design, I conducted research to 
         understand the users and their needs. To carry out the 
         research, I decided to use the following methods:
        </p>
         <ul>
          <li data-animation="paragraph">
           Surveys
          </li>
          <li data-animation="paragraph">
           Interviews 
          </li>
          <li data-animation="paragraph">
          Online research
          </li>
        
         </ul>

         <p data-animation="paragraph">
          I employed the use of google forms to ask people who have 
          rented a space or bought a property questions. I sent out the 
          survey to people of different occupations and professional 
          background to understand their different problems.
         </p>

         <p data-animation="paragraph">
          After getting the necessary responses, I recruited 
          4 people from the survey to get a deeper understanding 
          of the issues they faced or are facing in securing a 
          space or a property. 
         </p>

        </div>
        </div>      
        <div className="survey">
         <img src="../../assets/images/proximar/olatunbosun_awodiya_survey.png" alt="" />
         <p data-animation="paragraph">Some of the questions asked in the survey</p>
        </div>

        {/* Goals */}
         <div className="problems">
        <div className="problem_title">
         {/* <h3>Research</h3> */}
        </div>
        <div className="problem_content">
         <h3>Goals</h3>



        <p data-animation="paragraph">
         There were a couple of things I needed to achieve 
         with my entire research process. These include:
        </p>
         <ul>
          <li data-animation="paragraph">
           User needs and Preferences: This involved understanding 
           the users needs, pain points and preferences and to also 
           know what features and  functionality are most important 
           to them, and how they prefer to engage with real estate 
           products.
          </li>
          <li data-animation="paragraph">
           User Personas: This involved identifying key demographic  
           information about potential users, as well as identifying 
           their behaviours, motivations, and goals when searching 
           for real estate solutions.
          </li>
          <li data-animation="paragraph">
           Interface Design: This aims at getting the best features, 
           navigations and user interface practices that would suit 
           Proxima so as to ensure that it’s a user centred product.
          </li>
           <li data-animation="paragraph">
            Familiarity with Augmented Reality: This was to understand 
            potential users  familiarity with AR tools and technologies 
            and how they have been used. It also involved an in-depth 
            research on how AR can influence real estate and bring 
            about revenue increase
           </li>
         </ul>
        </div>
        </div>      

       <div className="user_persona">
       
        <img src="../../assets/images/proximar/olatunbosun_awodiya_percent.png" alt="" />
      </div>
       {/*User Persona  */}
       <div className="user_persona">
         <div className="user_persona_title">
          <h3 data-animation="paragraph">User Persona</h3>
         </div>
       </div>
       
       <div className="problems">
        <div className="problem_title">
         {/* <h3>Challenge</h3> */}
         <img src="../../assets/images/proximar/olatunbosun_awodiya_illustration_akin.png" alt="" className='person'/>
        </div>
        <div className="problem_content">
         <div className="details">
          <p>
           <span className="bold" data-animation="paragraph">
            Name:
           </span>
           <span data-animation="paragraph">
            Popoola Akin
           </span>
          </p>

          <p>
           <span className="bold" data-animation="paragraph">
            Age group:
           </span>
           <span data-animation="paragraph">
            20-25
           </span>
          </p>

          <p>
           <span className="bold" data-animation="paragraph">
            Occupation:
           </span >
           <span data-animation="paragraph">
            Student
           </span>
          </p>
         </div>
         <div className="proximar_about">
          <p>
           <span className='bold' data-animation="paragraph">About:</span> <span data-animation="paragraph">  Akin is a final year student in 
           the university. 
           Akin is looking for a conducive accommodation closer 
           to school that suits his needs and preferences.</span>
          </p>
           
         </div>
         <div className="pain" data-animation="paragraph">
          <h2>Pain points</h2> 
          <ul>
           <li data-animation="paragraph"> Lack of Price Transparency</li>
           <li data-animation="paragraph">Difficulty in searching for a new accommodation</li>
           <li data-animation="paragraph">No transparency in housing conditions</li>
          </ul> 
         </div>
         <div className="needs">
          <h2 data-animation="paragraph">Needs</h2> 
          <ul>
           <li data-animation="paragraph">To easily search for accommodation without back and forth</li>
           <li data-animation="paragraph">I would prefer if agents are more reliable and honest about housing conditions</li>
          </ul>
         </div>
     
        </div>
        </div>


        <div className="problems">
        <div className="problem_title">
         {/* <h3>Challenge</h3> */}
         <img src="../../assets/images/proximar/olatunbosun_awodiya_illustration_sarah.png" alt=""  className='person'/>
        </div>
        <div className="problem_content">
        <div className="details">
          <p>
           <span className="bold" data-animation="paragraph">
            Name:
           </span>
           <span data-animation="paragraph">
            Sarah Coker
           </span>
          </p>

          <p>
           <span className="bold" data-animation="paragraph">
            Age group:
           </span>
           <span data-animation="paragraph">
            29-32
           </span>
          </p>

          <p>
           <span className="bold" data-animation="paragraph">
            Occupation:
           </span>
           <span data-animation="paragraph">
            Marketing Manager
           </span>
          </p>
         </div>
         <div className="proximar_about">
          <p>
           <span className='bold' data-animation="paragraph">About:</span><span data-animation="paragraph"> Sarah is a manager who just got a new job and is moving to a new city. 
           She’s a very busy professional and on the search of a new home</span>
          </p>
           
         </div>
         <div className="pain">
          <h2 data-animation="paragraph">Pain points</h2> 
          <ul>
           <li data-animation="paragraph"> Searching for a new home is time consuming</li>
           <li data-animation="paragraph">Incomplete and inaccurate home information</li>
           <li data-animation="paragraph">Tight schedule to meet up with physical meetings</li>
          </ul> 
         </div>
         <div className="needs">
          <h2 data-animation="paragraph">Needs</h2> 
          <ul>
           <li data-animation="paragraph">Search for a new home with my preferred specifications</li>
           <li data-animation="paragraph">I want to avoid the hassle of back to back and multiple meetings and physical home tours as I have a tight schedule</li>
          </ul>
         </div>
     
        </div>
        </div>

        {/* Solution */}
        <div className="problems">
        <div className="problem_title">
         <h3 data-animation="paragraph">Solution</h3>
         {/* <img src="../../assets/images/proximar/placeholder.png" alt="" /> */}
        </div>
        <div className="problem_content">
        <p data-animation="paragraph">
         Once the research was concluded, I was then able to create a 
         detailed solutions to deal with all the problems of the 
         users and products
        </p>
        <p data-animation="paragraph">
        First I realised that there where 2 groups of people, 
        those who want to get a property and those who would 
        list one, buyers and owners respectively. If I was to 
        create a solution, I would need to design for both groups. 

        </p>
        <p data-animation="paragraph">
        A property owner would have to go through the process 
        of listing a property with it’s necessary information. 
        they would also  have to upload a 3D scan or a complete 
        model of the property for the AR tours and viewing 
        experience. 

         
        </p>
        <p data-animation="paragraph">
        Since a number of users don’t fully understand how to go 
        about using AR technologies, there would have to be a 
        walkthrough and explanation on how to effectively use 
        it in the app.
        </p>
        </div>
        </div>
       <div className="user_persona">
       <div className="user_persona_title">
        <h3 data-animation="paragraph">
         Userflow(for buyers)
        </h3>
       </div>

       <img src="../../assets/images/proximar/olatunbosun_awodiya_userflow.png" alt="" />
      </div>

        <div className="user_persona">
       <div className="user_persona_title">
        <h3 data-animation="paragraph">
         Sketches
        </h3>
       </div>

       <img src="../../assets/images/proximar/olatunbosun_awodiya_sketches.png" alt="" />
      </div>
      {/* Wireframes */}
       <div className="user_persona">
       <div className="user_persona_title">
        <h3 data-animation="paragraph">
         Wireframes
        </h3>
       </div>

       <img src="../../assets/images/proximar/olatunbosun_awodiya_wireframes.png" alt="" />
      </div>

      {/* Onboarding & signup */}
       <div className="user_persona">
       <div className="user_persona_title">
        <h3 data-animation="paragraph">
         Onboarding and Signup
        </h3>
       </div>

       <img src="../../assets/images/proximar/olatunbosun_awodiya_onboarding.png" alt="" />
       <img src="../../assets/images/proximar/olatunbosun_awodiya_signup.png" alt="" />
      </div>

      {/* Explore & find properties */}
       <div className="user_persona">
       <div className="user_persona_title">
        <h3 data-animation="paragraph">
         Explore and Find properties
        </h3>
       </div>

       <img src="../../assets/images/proximar/olatunbosun_awodiya_explore.png" alt="" />
       
      </div>

      {/* Property details */}
       <div className="user_persona">
       <div className="user_persona_title">
        <h3 data-animation="paragraph">
         Property details
        </h3>
        <p data-animation="paragraph">
         Users would be able to see the full details of properties
         they are interested in, including distance, sizes building conditions
        </p>
       </div>

       <img src="../../assets/images/proximar/olatunbosun_awodiya_property_details.png" alt="" />
       {/* <img src="../../assets/images/proximar/olatunbosun_awodiya_signup.png" alt="" /> */}
      </div>


      {/* Contact property owners */}
      <div className="user_persona">
       <div className="user_persona_title">
        <h3 data-animation="paragraph">
         Contact property owners
        </h3>
       </div>

       <img src="../../assets/images/proximar/olatunbosun_awodiya_contact.png" alt="" />
      </div>

      {/* 360 View and Virtual Tour */}
      <div className="user_persona">
       <div className="user_persona_title">
        <h3 data-animation="paragraph">
         360 View & Virtual Tour
        </h3>
        <p data-animation="paragraph">
         This would help the user to have a complete view of the rooms, 
         spaces and all areas of the building without going for a physical inspection. 
        </p>
        <p data-animation="paragraph">
         To make this effective, 3D scans and models of buildings would have 
         to be updated often so as to help the user properly view and identify 
         the conditions of the property
        </p>
        

       </div>

       <img src="../../assets/images/proximar/olatunbosun_awodiya_tour_1.png" alt="" />
      <div style={{padding: '77.91% 0 0 0', position: 'relative'}}  className='vimeo'>
        <iframe src="https://player.vimeo.com/video/829120935?h=126c0d7bf4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position: 'absolute', top:'0',left: '0',width: '100%', height: '100%'}} title="App walkthrough-main"></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
      </div>

      {/* Business takeaways */}
          <div className="problems">
        <div className="problem_title">
         <h3 className='business' data-animation="paragraph">Business takeaways</h3>
        </div>
        <div className="problem_content">
        <p data-animation="paragraph">
         From the entire process, I came up with 
         these conclusions that could help the business 
         side of things and provide competitive 
         advantage from other products
        </p>
         <ul>
          <li data-animation="paragraph">
           Partnering with reputable real estate agencies and data 
           providers to ensure that all listings are current and 
           accurate would be effective and to improve trustworthiness 
           among users would be a great strategy
          </li>
          <li >
           <span data-animation="paragraph"> The use of advanced AR technology is a new approach to deal 
           with inspection problems. It could completely eliminate 
           inspection fees and save buyers extra costs and 
           unforseen expenses.</span>
           <br />
            <span data-animation="paragraph">
           The proper utilization of this technology by the use of AR gadgets 
           such as AR glasses and headsets could see an increase in the amount 
           of users and revenue generated </span>
          </li>
          
         </ul>
        </div>
        </div>
        <div className="work_controls_btns">
           <NavLink to='/work/wholecare' className={({isActive}) =>(isActive)?'control':'control'} data-animation="paragraph">previous</NavLink>
           <NavLink to='/work/wholecare' className={({isActive}) =>(isActive)?'control':'control'} data-animation="paragraph">next</NavLink>
          </div>
          
       <Contact />
       </div>
    </div>
  )
}

export default Proximar