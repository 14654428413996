import './App.css';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import Home from './pages/Home';
import Work from './pages/Work';
import Youfinance from './pages/Youfinance';
import Wholecare from './pages/Wholecare'
import Proximar from './pages/Proximar'
import About from './pages/About';
import Illusrations from './pages/Illusrations';
import Swiftsell from './pages/Swiftsell';
import ContactPage from './pages/ContactPage';
import Preloader from './components/Preloader';
import scroll from "./animations/text";
import { useEffect } from 'react';
import Cursor  from './components/Cursor';
import ScrollToTop from './components/ScrollToTop';

function App() {
  
  return (
    <div className="app">
    <Cursor />
     {/* <Preloader />  */}
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path ='/' element = {<Home />}/>
            <Route path ='/work' element = {<Work />}/>
            <Route path ='/work/wholecare' element = {<Wholecare />}/>
            <Route path ='/work/proximar' element = {<Proximar />}/>
            <Route path ='/work/swiftsell' element = {<Swiftsell />}/>
            <Route path ='/illustrations' element = {<Illusrations />}/>
            <Route path ='/about' element={<About />} />
            
          </Routes>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
