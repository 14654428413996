import React from 'react'
import '../css/Contact.css'

function Contact() {
  return (
    <div className='contact'>
     <div className='links'>
      <div className="links_first">
       <p><a className='contact_link' href="mailto:olatunbosunawodiya@gmail.com">E-MAIL</a></p>
       <p><a  className='contact_link' href="https://behance.net/olatunbawodiya">BEHANCE</a></p>
       <p><a className='contact_link' href="https://www.linkedin.com/in/olatunbosun-awodiya-3b5b77207">LINKEDIN</a></p>
       <p><a className='contact_link' href="https://dribbble.com/Acemanny">DRIBBBLE</a></p>
      </div>
      <div className="sec">
        <img src="../../assets/computer_illustration_olatunbosun_awodiya.png" alt="Compueter illustration Olatunbosun Awodiya" />
         <div className="img_anim">
          <img src="../../assets/rotate.png" alt="rotating animation | Olatunbosun Awodiya" className='rotate'/>
          <div className="rotate_div">
            <img src="../../assets/arrow_up.png" alt="arrow down illustration | Olatunbosun Awodiya" className='arrow_down'/>
          </div>
         </div>
        </div>
      
     </div>
    </div>
  )
}

export default Contact