import React, {useEffect, useState} from 'react'
import NavB from '../components/NavB'
import '../css/About.css'
import SideBar from '../components/SideBar'
import Typed from 'react-typed'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {gsap} from 'gsap' ;
import ScrollTrigger from "gsap/ScrollTrigger";
import {split} from '../animations/text'
import scroll from "../animations/scroll";
import {
  Link
} from 'react-router-dom';
gsap.registerPlugin(ScrollTrigger);

// import { useMediaQuery } from '@material-ui/core'
// import '../animations/observe'

function About() {
  const [isPaused, setPaused ] = useState(false)
 
  useEffect(() => {
    scroll()
    split()
    AOS.init();
  
    // const Small = () => useMediaQuery('(min-width:320px)')
    // const Tab = () => useMediaQuery('(min-width:768px)')
    // let width;
    // let screen = window.innerWidth;
    // // console.log(screen)
    // if(screen >= 320 && screen < 375){
    //   width = '300px'
    // } else if(screen >= 360 && screen < 375){
    //   width = '370px'
    // } else if(screen >= 375 && screen < 425){
    //   width = '370px'
    // } else if(screen >= 425 && screen < 768){
    //   width = '400px'
    // } else if(screen >= 768 ){
    //   width = '550px'
    // }
    const imgAnim = () => {
   
    
    let num = 0
    
    setInterval(() => {
      if(num > 3){
        num = 0
       }
       document.querySelectorAll('.word').forEach(i=>{
        i.classList.remove('fill')
       })
      if(num> -1){
        // const imgPrev = document.querySelector('.one_img');
        // imgPrev.style.background = "../../assets/olatunbosun_awodiya_video_game.png"//data[num].img
         leftSlide()
        // document.querySelector(data[num].class).classList.add('fill')
        num = num + 1
      } 
    //  console.log(num)
    }, 5000);
     const data = [
      {
        img:"../../assets/olatunosun_awodiya_ar_vr_compressed.gif",
        class:'[data-word="design-work"]'
    
      },
     
      {
        img: "../../assets/olatunbosun_awodiya_video_game.png",
        class:'[data-word="reading"]'
      },
      {
        img: "../../assets/olatunbosun_awodiya_reading.png",
        class:'[data-word="video-games"]'
      },
       {
        img:"../../assets/olatunbosun_awodiya_design_work_compressed.gif",
        class:'[data-word="AR/VR"]'
      }

    ]

    // changing func
    var curpage = 1;
    var sliding = false;
    var click = true;
    var left = document.getElementById("left");
    var right = document.getElementById("right");
    var pagePrefix = "slide";
    var pageShift = 500;
    var transitionPrefix = "circle";
    var svg = true;

    function leftSlide() {
      
      if (click) {
     
        if (curpage == 1) curpage = 5;
        console.log("woek");
        sliding = true;
        curpage--;
        svg = true;
        click = false;
        for (let k = 1; k <= 4; k++) {
        // document.querySelectorAll('.word').forEach(word=>{
        //   word.classList.remove('fill')
        // })
        // console.log(k)
          var a1 = document.getElementById(pagePrefix + k);
          a1.className += " tran";
        }
        setTimeout(() => {
          move();
        }, 200);
        setTimeout(() => {
          for (let k = 1; k <= 4; k++) {
            var a1 = document.getElementById(pagePrefix + k);
            a1.classList.remove("tran");
            // document.querySelector(data[k - 1 ].class).classList.add('fill')
            // console.log(data[k - 1].class)
          }
        }, 1400);
      }
    }

    // function rightSlide() {
    //   if (click) {
    //     if (curpage == 4) curpage = 0;
    //     console.log("woek");
    //     sliding = true;
    //     curpage++;
    //     svg = false;
    //     click = false;
    //     for (let k = 1; k <= 4; k++) {
    //       var a1 = document.getElementById(pagePrefix + k);
    //       a1.className += " tran";
    //     }
    //     setTimeout(() => {
    //       move();
    //     }, 200);
    //     setTimeout(() => {
    //       for (let k = 1; k <= 4; k++) {
    //         var a1 = document.getElementById(pagePrefix + k);
    //         a1.classList.remove("tran");
    //       }
    //     }, 1400);
    //   }
    // }

    // setInterval(()=>{
     
    // }, 5000)

    function move() {
      if (sliding) {
        sliding = false;
        if (svg) {
          for (let j = 1; j <= 9; j++) {
            var c = document.getElementById(transitionPrefix + j);
            c.classList.remove("steap");
            c.setAttribute("class", transitionPrefix + j + " streak");
            console.log("streak");
          }
        } else {
          for (let j = 10; j <= 18; j++) {
            var c = document.getElementById(transitionPrefix + j);
            c.classList.remove("steap");
            c.setAttribute("class", transitionPrefix + j + " streak");
            console.log("streak");
          }
        }
        setTimeout(() => {
          for (let i = 1; i <= 4; i++) {
           
            if (i == curpage) {
              var a = document.getElementById(pagePrefix + i);
              a.className += " up1";
              let num = i - 1
              document.querySelector(data[num].class).classList.add('fill')
              console.log(i)
            } else {
              var b = document.getElementById(pagePrefix + i);
              b.classList.remove("up1");
            }

            //  document.querySelectorAll('.word').forEach(i=>{
            //   i.classList.remove('fill')
            // })
            // let num = 1
            // if(i > 4){
            //    num = 1
            // }
            // document.querySelectorAll('.word').forEach(i=>{
            //   i.classList.remove('fill')
            // })
            // if(num> -1){
              // const imgPrev = document.querySelector('.one_img');
              // imgPrev.style.background = "../../assets/olatunbosun_awodiya_video_game.png"//data[num].img
              // leftSlide()
              // document.querySelector(data[i].class).classList.add('fill')
              // num = num + 1
            // } 
          }
          sliding = true;
        }, 600);
        setTimeout(() => {
          click = true;
        }, 1700);

        setTimeout(() => {
          if (svg) {
            for (let j = 1; j <= 9; j++) {
              var c = document.getElementById(transitionPrefix + j);
              c.classList.remove("streak");
              c.setAttribute("class", transitionPrefix + j + " steap");
            }
          } else {
            for (let j = 10; j <= 18; j++) {
              var c = document.getElementById(transitionPrefix + j);
              c.classList.remove("streak");
              c.setAttribute("class", transitionPrefix + j + " steap");
            }
            sliding = true;
          }
        }, 850);
        setTimeout(() => {
          click = true;
        }, 1700);
      }
    }

    // left.onmousedown = () => {
    //   leftSlide();
    // };

    // right.onmousedown = () => {
    //   rightSlide();
    // };

    // document.onkeydown = e => {
    //   if (e.keyCode == 37) {
    //     leftSlide();
    //   } else if (e.keyCode == 39) {
    //     rightSlide();
    //   }
    // };
  }

 imgAnim()

})
 
 

  const toggleProduct = () => {
    const imgPrev = document.querySelector('.what_display');
    imgPrev.innerHTML =`<img width='100%' object-fit='cover'  
    src="../../assets/olatunbosun_awodiya_ui_design.png" 
    alt="" />
    `
  }

  const toggleVisual = () => {
    const imgPrev = document.querySelector('.what_display');
    imgPrev.innerHTML =`
  <img width='100%' object-fit='cover'  
    src="../../assets/olatunbosun_awodiya_visual_design.png" 
    alt="" />
    `
  }

  const toggle2d = () => {
    const imgPrev = document.querySelector('.what_display');
    imgPrev.innerHTML =`
  <img width='100%' object-fit='cover'  
    src="../../assets/olatunbosun_awodiya_2D.png" 
    alt="" />
    `
  }

  const toggle3d = () => {
    const imgPrev = document.querySelector('.what_display');
    imgPrev.innerHTML =`
   <img width='100%' object-fit='cover'  
    src="../../assets/olatunbosun_awodiya_3D_about.png" 
    alt="" />
    `

  }

  const mouseOut = () => {
    const imgPrev = document.querySelector('.what_display');
    imgPrev.innerHTML =``
  }

  useEffect(() => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let interval = null;

  document.querySelectorAll('.hacked').forEach(h=>{h.onmouseover = (event) => {
    let iteration = 0;

    clearInterval(interval);

    interval = setInterval(() => {
      event.target.innerText = event.target.innerText
        .split('')
        .map((letter, index) => {
          if (index < iteration) {
            return event.target.dataset.value[index];
          }
          return letters[Math.floor(Math.random() * 26)];
        })
        .join('');
      if (iteration >= event.target.dataset.value.length) {
        clearInterval(interval);
      }
      iteration += 1 / 3;
    }, 50);
  };
  })
  })
  

  

  return (
    <div className='about'>
       <h1>ABOUT ME</h1>
       {/* <div className="nav_conts"> */}
          <NavB />
          {/* <div  className="nav_border"></div>
        </div> */}
      <div className="about_body">
     
      <section className="about_intro">
       
       
        <SideBar />
        <div className="intro_contents">
          <div className="intro_text">

            <h2 className='passionate'>
              I am passionate about creating
              stunning and  user-friendly
              designs  that provides users with  
              the best experience
            </h2>
          
          <p data-animation="header">Welcome to my portfolio !</p>
        
          <p data-animation='header'>
           
            I'm Olatunbosun Awodiya , a passionate and 
            creative Product designer,  2D and 3D Illustrator 
            with 4 years of experience in the design industry.  
            My expertise lies in creating user-centered interfaces and experiences that are visually appealing, engaging and ultimately providing the needs of the user
          </p>
          <Link to='https://calendly.com/olatunbosunawodiya/chat-with-bosun' className='about_toggle'><p>Book a call <img src="../../assets/arrow_outward_black.png" alt=""/>
          </p></Link>
          
          {/* <p data-animation='paragraph'>My expertise lies in creating 
          user-centered interfaces,  experiences that are
          visually appealing and engaging and awesome illustrations</p> */}
            
          </div>

          <div data-aos="fade-down" className="intro_img">
            <div className="intro_img_div">
              <img src="../../assets/olatunbosun_awodiya_designer_illustrator.png" alt="" />
              {/* <div className="about_text">
                <img src="../../assets/ABOUT ME.png" alt="" />
              </div> */}
              
              
            </div>
            <div data-aos="fade-up" className="img_anim_about">
              <img src="../../assets/rotate.png" alt="rotating animation | Olatunbosun Awodiya" className='rotate'/>
              <div className="rotate_div">
                <img src="../../assets/arrow_down.png" alt="arrow down illustration | Olatunbosun Awodiya" className='arrow_down'/>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="more">
        <div className="more_one">
          <h2>little more <Typed strings={['...']} typeSpeed={150} showCursor={false} loop={true}/></h2>

          <p data-animation='paragraph'>
            My passion for design and 
            illustration has continued 
            to grow over the years. I have honed 
            my skills through numerous 
            projects and have always strived 
            to deliver top-notch work to my clients.
          </p>

          <p data-animation='paragraph'>
            My interests also extend to the 
            field of   <span className='ar int'>AR/VR </span>           
            and the possibilities that it offers 
            in creating digital experiences . 
          </p>
          
          <p data-animation='paragraph'>
            I believe in constantly learning 
            and exploring new fields to keep 
            my knowledge and skills up-to-date. 
            Whether it is experimenting with new 
            design techniques or exploring new 
            technologies, I am always eager to take 
            on new challenges and learn something new. 
            As a lifelong learner, I am always exploring 
            new fields and techniques to stay up-to-date 
            and bring new ideas to my work.
          </p>
          <div className="more_two_2">
            <p data-animation='paragraph'>
              When I'm not designing , 
              you'll find me <br/> 
              
              playing <span  className='game int'>video-games</span>, 
              drawing, <span className='read int'>reading</span>, or hanging out with friends.

              <br/> 

              My hobbies keep me inspired and motivated, 
              and I love to incorporate what <br/>I learn from 
              them into my  <span  className='desgn int'>design-work</span>.
            </p>
          </div>
          
        </div>
        <div className="more_two">
          <div className="more_two_1">
            <div className="one_img" data-aos="fade-down">
              <div className='parent_about'>
                <div className='slider'>
                 
                 
                  <svg id='svg2' className='up2' xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                    <circle id='circle1' className='circle1 steap' cx="34px" cy="49%" r="20"  />
                    <circle id='circle2' className='circle2 steap' cx="34px" cy="49%" r="100"  />
                    <circle id='circle3' className='circle3 steap' cx="34px" cy="49%" r="180"  />
                    <circle id='circle4' className='circle4 steap' cx="34px" cy="49%" r="260"  />
                    <circle id='circle5' className='circle5 steap' cx="34px" cy="49%" r="340"  />
                    <circle id='circle6' className='circle6 steap' cx="34px" cy="49%" r="420"  />
                    <circle id='circle7' className='circle7 steap' cx="34px" cy="49%" r="500"  />
                    <circle id='circle8' className='circle8 steap' cx="34px" cy="49%" r="580"  />
                    <circle id='circle9' className='circle9 steap' cx="34px" cy="49%" r="660"  />
                  </svg>
                  <svg id='svg1' className='up2' xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                    <circle id='circle10' className='circle10 steap' cx="648px" cy="49%" r="20"  />
                    <circle id='circle11' className='circle11 steap' cx="648px" cy="49%" r="100"  />
                    <circle id='circle12' className='circle12 steap' cx="648px" cy="49%" r="180"  />
                    <circle id='circle13' className='circle13 steap' cx="648px" cy="49%" r="260"  />
                    <circle id='circle14' className='circle14 steap' cx="648px" cy="49%" r="340"  />
                    <circle id='circle15' className='circle15 steap' cx="648px" cy="49%" r="420"  />
                    <circle id='circle16' className='circle16 steap' cx="648px" cy="49%" r="500"  />
                    <circle id='circle17' className='circle17 steap' cx="648px" cy="49%" r="580"  />
                    <circle id='circle18' className='circle18 steap' cx="648px" cy="49%" r="660"  />
                  </svg>
                <div id='slide1' className='slide1 up1'></div>
                <div id='slide2' className='slide2'></div>
                <div id='slide3' className='slide3'></div>
                <div id='slide4' className='slide4'></div>
              </div>
            </div>

          </div>
            {/* <div className="one_about">
              <img src="../../assets/ABOUT ME.png" alt="" />
            </div> */}
          </div>
         
          {/* <div className='border_outer'>
            <div className='border'>

            </div>
          </div> */}
         
        </div>
      </section>

      <section className="what">
        <div className="what_one">
          <h2 data-aos="fade-up">what  do I do</h2>
          <p data-aos="fade-right">
            from my journey in the design space, 
            I have experience in;
          </p>
        </div>

        <div className="what_two">
          <div className="what_display">

          </div>

          <ul>
            <li onMouseOver={toggleProduct} onMouseOut={mouseOut} data-value='PRODUCT DESIGN'>PRODUCT DESIGN</li>
            <li onMouseOver={toggleVisual} onMouseOut={mouseOut} data-value='VISUAL DESIGN'>VISUAL DESIGN</li>
            <li onMouseOver={toggle2d} onMouseOut={mouseOut} data-value='2D ILLUSTRATION'>2D ILLUSTRATION</li>
            <li onMouseOver={toggle3d} onMouseOut={mouseOut} data-value='3D ILLUSTRATION'>3D ILLUSTRATION</li>
          </ul>
          
        </div>

         {/* <div className='border_outer'>
            <div data-aos="fade-left" className='border'>

            </div>
          </div> */}
      </section>
      <section className="experience">
        <h2 data-aos="fade-right">
          experience
        </h2>

        <div className="exp_conts">
          <div className="exp_items">
            <div className="exp_item below">
              <p data-animation="paragraph" className='exp_col_1'>
                Product and Visual Designer
              </p>

              <p data-animation="paragraph" className='exp_col_2'>
                Fabreads
              </p>

              <p data-animation="paragraph" className='exp_col_3'>
                2019-2021
              </p>
            </div>

             <div className="exp_item below" >
            <p data-animation="paragraph" className='exp_col_1'>
              Digital Illustrator
            </p>

            <p data-animation="paragraph" className='exp_col_2'>
              Devcher 
            </p>

            <p data-animation="paragraph" className='exp_col_3'>
             2021
            </p>
          </div>

          <div className="exp_item below">
            <p data-animation="paragraph" className='exp_col_1'>
              Graphic Designer 
            </p>

            <p data-animation="paragraph" className='exp_col_2'>
              Edukoya
            </p>

            <p data-animation="paragraph" className='exp_col_3'>
              2021-2022
            </p>
          </div>

          <div className="exp_item">
            <p data-animation="paragraph" className='exp_col_1'>
              Product Designer
            </p>

            <p data-animation="paragraph" className='exp_col_2'>
              Knit Payments
            </p>

            <p data-animation="paragraph" className='exp_col_3'>
              2023
            </p>
          </div>
          <a href='https://drive.google.com/file/d/183a69OmocfSc1uGTSH2W_cUXnaejyoTG/view' className="resume">
            <p data-aos="fade-left">resume</p> 
            <div className="control_img" data-aos="fade-right">
              <img src="../../assets/right.png" alt="" />
            </div>
          </a>

          {/* <div className='border_outer'>
            <div className='border'>

            </div>
          </div> */}
          </div>
          {/* <img src="../../assets/ABOUT ME.png" alt="" /> */}
         
        </div>
      </section>
      <section className="enough">
        <div className="enough_intro">
          <p data-animation="header">
            well  that’s enough
            about me;
            </p>
          <div className="enough_you">
            <h2 data-aos="fade-up">What about you?</h2>
            <p data-animation="header">
              Have a project in mind? Need a Designer ?</p>
              <p data-animation="header">Get in touch</p>
          </div>
        </div>

        <div className='contact_about'>
          <div className='links'>
            <div className="links_first">
              <p data-aos="fade-down"><a className='contact_link' href="mailto:olatunbosunawodiya@gmail.com">E-MAIL</a></p>
              <p data-aos="fade-down"><a className='contact_link' href="https://behance.net/olatunbawodiya">BEHANCE</a></p>
              <p data-aos="fade-down"><a className='contact_link' href="https://www.linkedin.com/in/olatunbosun-awodiya-3b5b77207">LINKEDIN</a></p>
              <p data-aos="fade-down"><a className='contact_link' href="https://dribble.com/Acemanny">DRIBBBLE</a></p>
            </div>
            <div className="sec">
              <img data-aos="left" src="../../assets/computer_illustration_olatunbosun_awodiya.png" alt="Compueter illustration Olatunbosun Awodiya" />
              <div className="img_anim">
                <img src="../../assets/rotate.png" alt="rotating animation | Olatunbosun Awodiya" className='rotate'/>
                <div className="rotate_div">
                  <img src="../../assets/arrow_up.png" alt="arrow down illustration | Olatunbosun Awodiya" className='arrow_down'/>
                </div>
              </div>
              </div>
          </div>
          
          <div className="developed">
            <h2>developed by <a href='https://linktr.ee/adelabukamal'>Adelabu Kamal</a> </h2>
            <p>OLATUNBOSUN AWODIYA</p>
          </div>
        </div>
        
        
      </section>
      </div>
    </div>
  )
}

export default About