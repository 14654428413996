import React, {useEffect, useRef} from 'react'
import NavB from '../components/NavB'
import '../css/Work.css'
import SideBar from '../components/SideBar'
import {gsap, Power3, Power0} from 'gsap' ;
import $ from 'jquery'
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { duration } from '@material-ui/core';
import { Delay } from 'react-typing-animation';
import {split} from '../animations/text'
import { NavLink } from 'react-router-dom';
gsap.registerPlugin(ScrollToPlugin)


function Work() {
  let img = useRef(null)
  useEffect(()=>{
    split()
    // $(".work").on("load", function() {
    console.log('page loaded')
    var tween;
  
    // size of viewport with hidden overflow
    var $landingWrapper = $(".projects_list");
    
    // wraps slides, larger than viewport; coral background for visualization 
    var $landingInnerContent = $(".projects_inner_2");
    
    
    // AUTO-SCROLL 
    var xMove, yMove; 
    
    var isHovered = false;
  
      // detect scroll direction
      $landingInnerContent.on("mousemove touchmove", function(e) {
        // console.log('mouse movement')
        // yMove = "+=20"
        // if (e.clientX > $landingWrapper.width() / 2) {
        //   xMove = "+=500";
        // } else {
        //   xMove = "-=500";
        // }
        if (e.clientY > $landingWrapper.height()/4) {
          // console.log(e.clientY)
          // console.log($landingWrapper.height())
          yMove = "+=8";
        } else {
          yMove = "-=8";
        }
        
        // one tween per mouse call 
        if(!isHovered) {
          // console.log('hovered')
          tween = gsap.to($landingWrapper, {
            duration: 0.08,
            x:0,
            y: -((e.clientY * 3.5) - 350),
            z: 0,
            // smoothOrigin: true,
            // scrollTo: {
            //   y: yMove,
            //   smooth:true
            // },
            // ease: Power3.easeIn,
            // overwrite:"all" 
          });
        }
      });
      
    // })


    
  })


 const toggleProjectPreview = () => {
  const imgProjectOne = document.querySelector('.project');
  imgProjectOne.classList.toggle('hide');
 }
const toggleProximarPreview = () =>{
  const imgProjectOne = document.querySelector('.proximar');
  const tagText = document.querySelector('.prox_text')
   const imgPrev = document.querySelector('.project_display');
  imgProjectOne.classList.toggle('hide');
  tagText.classList.toggle('hide');

   imgPrev.innerHTML = `
   <span className=" project_one youfinance2 hide show" width=100% height=100vh>
   <div className='project_img_div'  >
    <img width='100%' height='100%' object-fit='cover' class="test" src="../../assets/proximar_olatunbosun_awodiya.png" alt="" 
    style="
      --panel-bottom-1: 0%;
      --panel-bottom-2: 0%;
      --panel-bottom-3: 0%;
      --panel-bottom-4: 0%;
      
      clip-path: polygon(0 0,
        0 var(--panel-bottom-1),
        25% var(--panel-bottom-1),
        25% 0,
        25% 0,
        25% var(--panel-bottom-2),
        50% var(--panel-bottom-2),
        50% 0,
        50% 0,
        50% var(--panel-bottom-3),
        75% var(--panel-bottom-3),
        75% 0,
        75% 0,
        75% var(--panel-bottom-4),
        100% var(--panel-bottom-4),
        100% 0
      );
    "
    />
   </div>
       
    <!--<p>
      TAGS:  3D ILLUSTRATION, UI DESIGN, 
      ICON DESIGN
    </p>-->
    </span>
  `

   // toggle image animation
    var tl = gsap
    tl.to(".test", {
      "--panel-bottom-1": 100,
      ease: "Power1.easeOut",
      duration: 1
    })
    tl.to(
      ".test",
      {
        "--panel-bottom-2": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.2
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-3": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.3
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-4": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.4
      },
		);

}

const toggleWholeCare = () => {
  const imgProjectOne = document.querySelector('.wholecare');
  const tagText = document.querySelector('.whole_tag')
  const imgPrev = document.querySelector('.project_display');
  imgProjectOne.classList.toggle('hide');
  tagText.classList.toggle('hide');

  imgPrev.innerHTML = `
   <span className=" project_one youfinance2 hide show" width=100% height=100vh>
   <div className='project_img_div'  >
    <img width='100%' height='100%' object-fit='cover' class="test" src="../../assets/olatunbosun_awodiya_whole_care.png" alt="" 
    style="
      --panel-bottom-1: 0%;
      --panel-bottom-2: 0%;
      --panel-bottom-3: 0%;
      --panel-bottom-4: 0%;
      
      clip-path: polygon(0 0,
        0 var(--panel-bottom-1),
        25% var(--panel-bottom-1),
        25% 0,
        25% 0,
        25% var(--panel-bottom-2),
        50% var(--panel-bottom-2),
        50% 0,
        50% 0,
        50% var(--panel-bottom-3),
        75% var(--panel-bottom-3),
        75% 0,
        75% 0,
        75% var(--panel-bottom-4),
        100% var(--panel-bottom-4),
        100% 0
      );
    "
    />
   </div>
    </span>
  `

   // toggle image animation
    var tl = gsap
    tl.to(".test", {
      "--panel-bottom-1": 100,
      ease: "Power1.easeOut",
      duration: 1
    })
    tl.to(
      ".test",
      {
        "--panel-bottom-2": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.2
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-3": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.3
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-4": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.4
      },
		);
}

const toggleSwiftSell = () => {
  const imgProjectOne = document.querySelector('.swiftsell');
  const tagText = document.querySelector('.swift_tag')
  const imgPrev = document.querySelector('.project_display');
  imgProjectOne.classList.toggle('hide');
  tagText.classList.toggle('hide');
 imgPrev.innerHTML = `
   <span className=" project_one youfinance2 hide show" width=100% height=100vh>
   <div className='project_img_div'  >
    <img width='100%' height='100%' object-fit='cover' class="test" src="../../assets/swiftsell_olatunbosun_awodiya.png" alt="" 
    style="
      --panel-bottom-1: 0%;
      --panel-bottom-2: 0%;
      --panel-bottom-3: 0%;
      --panel-bottom-4: 0%;
      
      clip-path: polygon(0 0,
        0 var(--panel-bottom-1),
        25% var(--panel-bottom-1),
        25% 0,
        25% 0,
        25% var(--panel-bottom-2),
        50% var(--panel-bottom-2),
        50% 0,
        50% 0,
        50% var(--panel-bottom-3),
        75% var(--panel-bottom-3),
        75% 0,
        75% 0,
        75% var(--panel-bottom-4),
        100% var(--panel-bottom-4),
        100% 0
      );
    "
    />
   </div>
       
    <!--<p>
      TAGS:  3D ILLUSTRATION, UI DESIGN, 
      ICON DESIGN
    </p>-->
    </span>
  `

   // toggle image animation
    var tl = gsap
    tl.to(".test", {
      "--panel-bottom-1": 100,
      ease: "Power1.easeOut",
      duration: 1
    })
    tl.to(
      ".test",
      {
        "--panel-bottom-2": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.2
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-3": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.3
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-4": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.4
      },
		);
  
}
 const toggleYouPreview = () => {
  const imgProjectOne = document.querySelector('.youfinance');
  const imgPrev = document.querySelector('.project_display');
  const tagText = document.querySelector('.you_tag')
  imgProjectOne.classList.toggle('hide');
  tagText.classList.toggle('hide');
  imgPrev.innerHTML = `
   <span className=" project_one youfinance2 hide show" width=100% height=100vh>
   <div className='project_img_div'  >
    <img width='100%' height='100%' object-fit='cover' class="test" src="../../assets/youfinance_illustration_olatunbosun_awodiya.png" alt="" 
    style="
      --panel-bottom-1: 0%;
      --panel-bottom-2: 0%;
      --panel-bottom-3: 0%;
      --panel-bottom-4: 0%;
      
      clip-path: polygon(0 0,
        0 var(--panel-bottom-1),
        25% var(--panel-bottom-1),
        25% 0,
        25% 0,
        25% var(--panel-bottom-2),
        50% var(--panel-bottom-2),
        50% 0,
        50% 0,
        50% var(--panel-bottom-3),
        75% var(--panel-bottom-3),
        75% 0,
        75% 0,
        75% var(--panel-bottom-4),
        100% var(--panel-bottom-4),
        100% 0
      );
    "
    />
   </div>
       
    <!--<p>
      TAGS:  3D ILLUSTRATION, UI DESIGN, 
      ICON DESIGN
    </p>-->
    </span>
  `

   // toggle image animation
    var tl = gsap
    tl.to(".test", {
      "--panel-bottom-1": 100,
      ease: "Power1.easeOut",
      duration: 1
    })
    tl.to(
      ".test",
      {
        "--panel-bottom-2": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.2
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-3": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.3
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-4": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.4
      },
		);

 }

 const toggleFitPreview = () => {
  const imgProjectOne = document.querySelector('.fit-bud');
  const tagText = document.querySelector('.fit_tag')
  const imgPrev = document.querySelector('.project_display');
  imgProjectOne.classList.toggle('hide');
  tagText.classList.toggle('hide');
   imgPrev.innerHTML = `
   <span className=" project_one fit-bud hide show" width=100% height=100vh>
   <div className='project_img_div'  >
    <img width='100%' height='100%' object-fit='cover' class="test" src="../../assets/knit.png" alt="" 
    style="
      --panel-bottom-1: 0%;
      --panel-bottom-2: 0%;
      --panel-bottom-3: 0%;
      --panel-bottom-4: 0%;
      
      clip-path: polygon(0 0,
        0 var(--panel-bottom-1),
        25% var(--panel-bottom-1),
        25% 0,
        25% 0,
        25% var(--panel-bottom-2),
        50% var(--panel-bottom-2),
        50% 0,
        50% 0,
        50% var(--panel-bottom-3),
        75% var(--panel-bottom-3),
        75% 0,
        75% 0,
        75% var(--panel-bottom-4),
        100% var(--panel-bottom-4),
        100% 0
      );
    "
    />
   </div>
       
    <!--<p>
      TAGS:  3D ILLUSTRATION, UI DESIGN, 
      ICON DESIGN
    </p>-->
    </span>
  `

   // toggle image animation
    var tl = gsap
    tl.to(".test", {
      "--panel-bottom-1": 100,
      ease: "Power1.easeOut",
      duration: 1
    })
    tl.to(
      ".test",
      {
        "--panel-bottom-2": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.2
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-3": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.3
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-4": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.4
      },
		);


 }
 const mouseOut = () => {
  const imgPrev = document.querySelector('.project_display');
  imgPrev.innerHTML = ``
  const activeBtn = document.querySelectorAll('.img_div')
  const activeText = document.querySelectorAll('.tag_text')
  activeBtn.forEach(btn => btn.classList.add('hide'))
  activeText.forEach(btn => btn.classList.add('hide'))
 }

 const toggleHeadPreview = () => {
  const imgProjectOne = document.querySelector('.headset');
  const imgPrev = document.querySelector('.project_display');
  const tagText = document.querySelector('.head_tag')
  imgProjectOne.classList.toggle('hide');
  tagText.classList.toggle('hide');
  imgPrev.innerHTML = `
   <span className=" project_one headset2 hide show" height=100vh width="100%">
      <img width='100%' class="test" background-size='cover' object-fit='cover'    src="../../assets/headset_ar_olatunbosun_awodiya.png" alt="" 
      style="
      --panel-bottom-1: 0%;
      --panel-bottom-2: 0%;
      --panel-bottom-3: 0%;
      --panel-bottom-4: 0%;
      
      clip-path: polygon(0 0,
        0 var(--panel-bottom-1),
        25% var(--panel-bottom-1),
        25% 0,
        25% 0,
        25% var(--panel-bottom-2),
        50% var(--panel-bottom-2),
        50% 0,
        50% 0,
        50% var(--panel-bottom-3),
        75% var(--panel-bottom-3),
        75% 0,
        75% 0,
        75% var(--panel-bottom-4),
        100% var(--panel-bottom-4),
        100% 0
      );
    "
       />
       <!--<p>
        TAGS:  AR/VR DESIGN, 3D DESIGN, 
        ADOBE AERO
       </p>-->
      </span>
  `

   // toggle image animation
    var tl = gsap
    tl.to(".test", {
      "--panel-bottom-1": 100,
      ease: "Power1.easeOut",
      duration: 1
    })
    tl.to(
      ".test",
      {
        "--panel-bottom-2": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.2
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-3": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.3
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-4": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.4
      },
		);

 }

 const toggleFabPreview = () => {
  const imgProjectOne = document.querySelector('.fabreads')
  const imgPrev = document.querySelector('.project_display')
  const tagText = document.querySelector('.fab_tag')
  // const list_item = document.querySelector('list_item_fab')
  // list_item.style.current.color = '#F6CFA0'
  imgProjectOne.classList.toggle('hide')
  tagText.classList.toggle('hide')

  imgPrev.innerHTML = `
    <span className=" project_one fabreads2 hide show" width=100% height=100vh>
      <img width='100%' 
      height='100%' 
      background-size='cover' 
      class="test" 
      src="../../assets/fabreads_olatunbosun_awodiya.png" alt="" 

      style="
      --panel-bottom-1: 0%;
      --panel-bottom-2: 0%;
      --panel-bottom-3: 0%;
      --panel-bottom-4: 0%;
      
      clip-path: polygon(0 0,
        0 var(--panel-bottom-1),
        25% var(--panel-bottom-1),
        25% 0,
        25% 0,
        25% var(--panel-bottom-2),
        50% var(--panel-bottom-2),
        50% 0,
        50% 0,
        50% var(--panel-bottom-3),
        75% var(--panel-bottom-3),
        75% 0,
        75% 0,
        75% var(--panel-bottom-4),
        100% var(--panel-bottom-4),
        100% 0
      );
    "

      />
      <!--<p>
       TAGS:  EDTECH, UI/UX DESIGN,  
       BRAND DESIGN, E-LEARNING
      </p>-->
      </span>
  `

   // toggle image animation
    var tl = gsap
    tl.to(".test", {
      "--panel-bottom-1": 100,
      ease: "Power1.easeOut",
      duration: 1
    })
    tl.to(
      ".test",
      {
        "--panel-bottom-2": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.2
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-3": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.3
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-4": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.4
      },
		);

 }

 const toggleKamPreview = () => {
  const imgProjectOne = document.querySelector('.kamaldeen')
  const tagText = document.querySelector('.kamal_tag')
  imgProjectOne.classList.toggle('hide')
  tagText.classList.toggle('hide');
 }

 const togglePortfolio = () => {
  const imgProjectOne = document.querySelector('.portfolio')
   const imgPrev = document.querySelector('.project_display');
  const tagText = document.querySelector('.port_tag')
  imgProjectOne.classList.toggle('hide')
  tagText.classList.toggle('hide');
  imgPrev.innerHTML = `
    <span className=" project_one portfolio hide show" width=100% height=100vh>
      <img width='100%' 
      height='100%' 
      object-fit='cover'
      class="test" 
      src="../../assets/olatunbosun_awodiya_3d.PNG" alt="" 

      style="
      --panel-bottom-1: 0%;
      --panel-bottom-2: 0%;
      --panel-bottom-3: 0%;
      --panel-bottom-4: 0%;
      
      clip-path: polygon(0 0,
        0 var(--panel-bottom-1),
        25% var(--panel-bottom-1),
        25% 0,
        25% 0,
        25% var(--panel-bottom-2),
        50% var(--panel-bottom-2),
        50% 0,
        50% 0,
        50% var(--panel-bottom-3),
        75% var(--panel-bottom-3),
        75% 0,
        75% 0,
        75% var(--panel-bottom-4),
        100% var(--panel-bottom-4),
        100% 0
      );
    "

      />
      <!--<p>
       TAGS:  EDTECH, UI/UX DESIGN,  
       BRAND DESIGN, E-LEARNING
      </p>-->
      </span>
  `

   // toggle image animation
    var tl = gsap
    tl.to(".test", {
      "--panel-bottom-1": 100,
      ease: "Power1.easeOut",
      duration: 1
    })
    tl.to(
      ".test",
      {
        "--panel-bottom-2": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.2
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-3": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.3
      },
    )
    tl.to(
      ".test",
      {
        "--panel-bottom-4": 100,
        ease: "Power1.easeOut",
        duration: 1,
        delay: 0.4
      },
		);

 }

  let screen = window.innerWidth;

  return (
  <div className='work'>
    <div className="nav_conts">
          <NavB />
          
        </div>

   <div className="project_div">
     <div className="projects_list">
      {screen >= 960?(
      <div className="projects_inner projects_inner_2">
        <NavLink to='/work/proximar'>
          <div className="work_text">
            <p onMouseOver={toggleProximarPreview} onMouseOut={mouseOut} className='proximar_title'>PROXIMAR <div className="img_div proximar hide">
            <img src="../../assets/Union.png" alt=""  className='' /></div></p>
            <small className='prox_text hide tag_text'>
              <span className='tag'>product design</span>
              <span className='tag'>real estate</span>
              <span className='tag'>mobile app</span>
            </small>
          </div>
          
        </NavLink>

        <NavLink to='/work/wholecare' onMouseOver={toggleWholeCare} onMouseOut={mouseOut}>
          <div className="work_text">
            <p >WHOLECARE <div className="img_div wholecare hide">
            <img src="../../assets/Union.png" alt=""  className='' /></div></p>
            <small className=' whole_tag hide  tag_text'>
              <span className='tag'>product design</span>
              <span className='tag'>wellness</span>
              <span className='tag'>mobile app</span>
            </small>
          </div>
          
        </NavLink>

        <NavLink to='/work/swiftsell'>
          <div className="work_text">
            <p onMouseOver={toggleSwiftSell} onMouseOut={mouseOut}>SWIFTSELL<div className="img_div swiftsell hide">
            <img src="../../assets/Union.png" alt=""  className='' /></div></p>
            <small className='swift_tag hide tag_text'>
              <span className='tag'>product design</span>
              <span className='tag'>e-commerce</span>
              <span className='tag'>web app</span>
            </small>
          </div>
          
       </NavLink>

        <a href='https://useknit.io'>
          <div className="work_text">
            <p onMouseOver={toggleFitPreview} onMouseOut={mouseOut}>KNIT <div className="img_div fit-bud hide">
          <img src="../../assets/Union.png" alt="" /></div> </p>
            <small className='fit_tag hide tag_text'>
              <span className='tag'>product design</span>
              <span className='tag'>fin tech</span>
              <span className='tag'>mobile app</span>
              {/* <span className='tag'>coming soon</span> */}
            </small>
          </div>
        </a>

        <a href="https://www.behance.net/gallery/160804439/Youfinance-3D-Brand-Characters-and-Illu" >
          <div className="work_text">
            <p onMouseOver={toggleYouPreview} onMouseOut={mouseOut} >YOUFINANCE  <div className="img_div youfinance hide"><img src="../../assets/Union.png" alt="" /></div> </p>
            {/* <small className='you_tag hide show_ tag_text'>, ,  ICON DESIGN</small> */}
             <small className='you_tag hide tag_text'>
              <span className='tag'>3D ILLUSTRATION</span>
              <span className='tag'>branding</span>
              <span className='tag'>UI DESIGN</span>
            </small>
          </div>
          
          </a>
        <a href='https://www.behance.net/gallery/157141731/VR-HEADSET-AR-Experience'>
          <div className="work_text">
           <p onMouseOver={toggleHeadPreview} onMouseOut={mouseOut}>HEADSET AR <div className="img_div headset  hide"><img src="../../assets/Union.png" alt="" /> </div></p>
           {/* <small className='head_tag hide show_ tag_text'>  DESIGN, , ADOBE AERO</small> */}
            <small className='head_tag hide tag_text'>
              <span className='tag'>AR/VR</span>
              <span className='tag'>3D DESIGN</span>
              {/* <span className='tag'>UI DESIGN</span> */}
            </small>
          </div>  
          </a>
        <a href='https://fabreads.com'>
          <div className="work_text">
            <p onMouseOver={toggleFabPreview} onMouseOut={mouseOut}className='list_item_fab'>FABREADS<div className="img_div fabreads hide"><img src="../../assets/Union.png" alt="" /> </div></p>
            {/* <small className='fab_tag hide show_ tag_text'>EDTECH, UI/UX DESIGN,  
       BRAND DESIGN, E-LEARNING</small> */}
        <small className='fab_tag hide tag_text'>
              <span className='tag'>product design</span>
              <span className='tag'>ed tech</span>
              <span className='tag'>website</span>
            </small>
          </div>
            
          </a>
        <a >
          <div className="work_text">
            <p onMouseOver={toggleKamPreview} onMouseOut={mouseOut}>KAMALDEEN <div className="img_div kamaldeen hide"><img src="../../assets/Union.png" alt="" /> </div></p>
            <small className='kamal_tag hide tag_text'>
              <span className='tag'>ui/ux</span>
              <span className='tag'>website</span>
              <span className='tag'>portfolio</span>
              <span className='tag'>coming soon</span>
            </small>
          </div>
           
          </a>     

        <a href='/'>
          <div className="work_text">
            <p onMouseOver={togglePortfolio} onMouseOut={mouseOut}>MY PORTFOLIO<div className="img_div portfolio hide">
            <img src="../../assets/Union.png" alt=""  className='' /></div></p>
            <small className='port_tag hide tag_text'>
              <span className='tag'>ui/ux</span>
              <span className='tag'>website</span>
              <span className='tag'>portfolio</span>
            </small>
          </div>
          
        </a>
       
      </div>

      ):(

        <div className="projects_inner">
        <NavLink to='/work/proximar'>
          <div className="work_text">
            <p className='proximar_title'>PROXIMAR </p>
          </div>
          
        </NavLink>

        <NavLink to='/work/wholecare'>
          <div className="work_text">
            <p >WHOLECARE </p>
          </div>
          
        </NavLink>

        <NavLink to='/work/swiftsell'>
          <div className="work_text">
            <p>SWIFTSELL</p>
            
          </div>
          
       </NavLink>

        <a href='https://useknit.io'>
          <div className="work_text">
            <p>KNIT</p>
            
          </div>
        </a>

        <a href="https://www.behance.net/gallery/160804439/Youfinance-3D-Brand-Characters-and-Illu" >
          <div className="work_text">
            <p>YOUFINANCE   </p>
            
            
          </div>
          
          </a>
        <a href='https://www.behance.net/gallery/157141731/VR-HEADSET-AR-Experience'>
          <div className="work_text">
           <p>HEADSET AR </p>
        
            
          </div>  
          </a>
        <a href='https://fabreads.com'>
          <div className="work_text">
            <p className='list_item_fab'>FABREADS</p>
            
        
          </div>
            
          </a>
        {/* <a >
          <div className="work_text">
            <p>KAMALDEEN </p>
            
          </div>
           
          </a>      */}

        <a href='/'>
          <div className="work_text">
            <p>MY PORTFOLIO</p>
          </div>
          
        </a>
       
      </div>
      )}

     
     </div>
     <div className="project_display">
     
     </div>
   </div>
    
  </div>
  )
}

export default Work